<template>
  <div>
    <b-modal
      hide-footer
      size="xl"
      id="modal-detail"
      centered
      title="Detail Appointment"
      title-class="font-18" 
      @close="$emit('close')"
      no-close-on-backdrop
      >
      <div class="d-inline-block w-100 pad-10" v-if="appointment">
        <div class="row">
          <div class="col-md-12 shadow-card for-head">
            <div class="row">
              <div class="col-md-12 mb-10">
                <b-badge v-if="appointment.tag" class="float-right" variant="warning">{{ appointment.tag }}</b-badge>
                <span class="font-16 d-block text-white font-weight-bold pb-6 border-bottom">Booking Status</span>
              </div>
              <div class="w-20 pl-12 pr-12 mb-3">
                  <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                    Booking Code 
                  </span>
                  <span class="text-white font-weight-bold">{{ appointment.booking_code}}</span>
              </div> 
              <div class="w-20 pl-12 pr-12 mb-3">
                  <span class="d-block text-white opacity-5 font-weight-bold font-12  ">
                    Booking Status 
                  </span>
                  <span class="text-white font-weight-bold">{{ appointment.status}}</span>
              </div> 
              <div class="w-20 pl-12 pr-12 mb-3">
                  <span class="d-block font-weight-bold opacity-5 font-12 text-white">
                    Payment Status 
                  </span>
                  <span class="text-white font-weight-bold">
                    {{ appointment.payment_status | capitalize}}
                    <span v-if="appointment.payment_status == 'paid'">({{ appointment.payment_method | capitalize }})</span>
                  </span>
              </div> 
              <div class="w-20 pl-12 pr-12 mb-3">
                  <span class="d-block font-weight-bold font-12 opacity-5 text-white">
                   Date
                  </span>
                  <span class="text-white font-weight-bold"> {{ appointment.date | date_only }}  {{ appointment.time }}</span>
              </div>
              <div class="w-20 pl-12 pr-12 mb-3">
                  <span class="d-block font-weight-bold font-12 text-white opacity-5">
                    Booking at  
                  </span>
                  <span class="text-white font-weight-bold">{{ appointment.createdAt | date_time }}</span>
              </div>
              <div class="w-20 pl-12 pr-12" v-if="appointment.payment_status == 'refunded'">
                  <span class="d-block font-weight-bold font-12 text-white opacity-5">
                    Refund Reason  
                  </span>
                  <span class="text-white font-weight-bold">{{ appointment.refund_reason }}</span>
              </div>
            </div> 
          </div>

          <div class="col-md-12 shadow-card" v-if="appointment.notes">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Booking Notes</span>
              </div>
              <div class="col-md-12">
                <span class="text-secondary font-weight-bold">{{ appointment.notes }}</span>
              </div>
            </div> 
          </div>

          <div class="col-md-12 shadow-card">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Detail User</span>
              </div>
              <div class="col-md-3">
                  <span class="d-block font-weight-bold font-12 text-primary">
                    Firstname 
                  </span>
                  <span class="text-secondary font-weight-bold">{{ appointment.user.firstname | capitalize }}</span>
              </div> 
              <div class="col-md-3">
                  <span class="d-block font-weight-bold font-12 text-primary">
                    Lastname 
                  </span>
                  <span class="text-secondary font-weight-bold">{{ appointment.user.lastname | capitalize }}</span>
              </div> 
              <div class="col-md-3">
                  <span class="d-block font-weight-bold font-12 text-primary">
                    Email 
                  </span>
                  <span class="text-secondary font-weight-bold">{{ appointment.user.email }}</span>
              </div>
              <div class="col-md-3">
                  <span class="d-block font-weight-bold font-12 text-primary">
                    Phone 
                  </span>
                  <span class="text-secondary font-weight-bold">{{ appointment.user.phone }}</span>
              </div>
            </div> 
          </div>
          
          <div class="col-md-6 shadow-card">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Detail location</span>
              </div>
              <div class="col-md-12 mb-2">
                <span class="d-block font-weight-bold font-12 text-primary">
                  Location Name  
                </span>
                <span class="text-secondary font-weight-bold">{{ appointment.location.name }}</span>
              </div> 
              <div class="col-md-12 mb-2">
                <span class="d-block font-weight-bold font-12 text-primary">
                  Location Address 
                </span>
                <span class="text-secondary font-weight-bold">{{ appointment.location.address }}</span>
              </div> 
              <div class="col-md-12 mb-2">
                <span class="d-block font-weight-bold font-12 text-primary">
                  Location Phone 
                </span>
                <span class="text-secondary font-weight-bold">{{ appointment.location.phone }}</span>
              </div> 
            </div>
          </div>
        
          <div class="col-md-6 shadow-card">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Detail Room</span>
              </div>
              <div class="col-md-12 mb-2">
                <span class="d-block font-weight-bold font-12 text-primary">
                  Room Name  
                </span>
                <span class="text-secondary font-weight-bold">{{ (appointment.room)? appointment.room.name : '-' }}</span>
              </div> 
              <div class="col-md-12 mb-2">
                <span class="d-block font-weight-bold font-12 text-primary">
                  Room Description 
                </span>
                <span class="text-secondary font-weight-bold">{{ (appointment.room)? appointment.room.description : '-' }}</span>
              </div> 
            </div>
          </div>
          
          <div class="col-md-6 shadow-card">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Treatment </span>
              </div>
              <template v-for="(data, index) in appointment.treatments">
                <div :key="index">
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Name  
                    </span>
                    <span class="text-secondary font-weight-bold">{{ data.treatment.name }}</span>
                  </div> 
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Price
                    </span>
                    <span class="text-secondary font-weight-bold">Rp. {{ data.price | convertToRupiah }}</span>
                  </div> 
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Duration 
                    </span>
                    <span class="text-secondary font-weight-bold">{{ data.treatment.duration }} minutes</span>
                  </div> 
                </div>
              </template>
            </div>
          </div>

          <div class="col-md-6 shadow-card">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Employee </span>
              </div>
              <template v-for="(data, index) in appointment.treatments">
                <div :key="index">
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Name  
                    </span>
                    <span class="text-secondary font-weight-bold">{{ (data.employee)? data.employee.name : '-' }}</span>
                  </div> 
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Phone
                    </span>
                    <span class="text-secondary font-weight-bold">{{ (data.employee)? data.employee.phone : '-' }}</span>
                  </div> 
                </div>
              </template>
            </div>
          </div>

          <div class="col-md-6 shadow-card" v-if="packages.length">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Package </span>
              </div>
              <template v-for="(data, index) in packages">
                <div :key="index">
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Name  
                    </span>
                    <span class="text-secondary font-weight-bold">{{ data.package.name }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="col-md-6 shadow-card" v-if="appointment.treatmentPackages.length">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Treatment Packages </span>
              </div>
              <template v-for="(data, index) in appointment.treatmentPackages">
                <div :key="index">
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Name  
                    </span>
                    <span class="text-secondary font-weight-bold">{{ data.name }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="col-md-12 shadow-card">
            <div class="row">
              <div class="col-md-12 mb-10 ">
                <span class="font-16 d-block text-primary font-weight-bold pb-6 border-bottom">Products </span>
              </div>
              <template v-for="(data, index) in appointment.products">
                <div :key="index">
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Name  
                    </span>
                    <span class="text-secondary font-weight-bold">{{ data.name }}</span>
                  </div> 
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Price
                    </span>
                    <span class="text-secondary font-weight-bold">Rp. {{ data.price | convertToRupiah }}</span>
                  </div> 
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      Quantity 
                    </span>
                    <span class="text-secondary font-weight-bold">{{ data.quantity }}</span>
                  </div> 
                  <div class="col-md-12 mb-2">
                    <span class="d-block font-weight-bold font-12 text-primary">
                      SubTotal 
                    </span>
                    <span class="text-secondary font-weight-bold">Rp. {{ (data.price * data.quantity) | convertToRupiah }}</span>
                  </div> 
                </div>
              </template>
            </div>
          </div>

          <div class="col-md-12 shadow-card for-head">
            <div class="row">
              <div class="col-md-12 mb-10">
                <span class="font-16 d-block text-white font-weight-bold pb-6 border-bottom">Summary Payment</span>
              </div>
              <div class="col-md-2 mb-10">
                <span class="d-block text-white opacity-5 font-weight-bold font-12">
                  Total Treatment 
                </span>
                <span class="text-white font-weight-bold font-18">Rp. {{ totalTreatment | convertToRupiah }}</span>
              </div>
              <div class="col-md-2 mb-10">
                <span class="d-block text-white opacity-5 font-weight-bold font-12">
                  Total Product 
                </span>
                <span class="text-white font-weight-bold font-18">Rp. {{ totalProduct | convertToRupiah }}</span>
              </div>
              <div class="col-md-2 mb-10">
                <span class="d-block text-white opacity-5 font-weight-bold font-12">
                  Total Package 
                </span>
                <span class="text-white font-weight-bold font-18">Rp. {{ appointment.discountPackage | convertToRupiah }}</span>
              </div>
              <div class="col-md-2 mb-10">
                <span class="d-block text-white opacity-5 font-weight-bold font-12">
                  Discount 
                </span>
                <span class="text-white font-weight-bold font-18">Rp. {{ appointment.discountPackageBalancing | convertToRupiah }}</span>
              </div>
              <div class="col-md-2 mb-10">
                <span class="d-block text-white opacity-5 font-weight-bold font-12">
                  Voucher  <span v-if="appointment.voucher">( {{ appointment.voucher.code }} )</span>
                </span>
                <span class="text-white font-weight-bold font-18">Rp. {{ appointment.discountVoucher | convertToRupiah}}</span>
              </div>
              <div class="col-md-2 mb-10">
                <div class="float-right">
                  <span class="d-block text-white opacity-5 font-weight-bold font-12">
                    Total 
                  </span>
                  <span class="text-white font-weight-bold font-18">Rp. {{ appointment.total | convertToRupiah }}</span>
                </div>
              </div>
            </div> 
          </div>
        </div> 
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'detail-appointment',
  props: {
    id: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      appointment: null,
      packages: []
    }
  },
  watch: {
    id(newVal) {
      if (newVal !== null) {
        this.getDetail()
      } 
    }
  },
  computed: {
    totalTreatment() {
      let total = 0
      this.appointment.treatments.forEach( treatment => {
        total += treatment.price
      });
      return total;
    },
    totalProduct() {
      let total = 0
      this.appointment.products.forEach( product => {
        total += product.price * product.quantity
      });
      return total;
    }
  },
  methods: {
    getDetail() {
      this.$bvModal.show('modal-detail')
      this.$axios.get(`appointment/${this.id}`)
        .then( response => {
          this.appointment = response.data.result.appointment
          this.packages = response.data.result.packages
        })
    }
  }
}
</script>