<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";

import ListAppointment from './list-appointment';

export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    ListAppointment
  },
  mounted() {
    this.fetchResume()
  },
  data() {
    return {
      total_users: 0,
      total_treatments: 0,
      total_appointments: 0,
      total_packages: 0
    };
  },
  methods: {
    fetchResume() {
      this.$axios.get('/resume/total', {
        params: {
          location: this.$store.getters.getStaffLocationId
        }
      })
        .then( response => {
          const result = response.data.result
          this.total_users = result.total_users
          this.total_treatments = result.total_treatments,
          this.total_appointments = result.total_appointments,
          this.total_packages = result.total_packages
        })
    }
  }
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">Dashboard</h4>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item active">Welcome to Surface Dashboard</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <div class="card mini-stat bg-primary text-white">
          <div class="card-body">
            <div class="mb-4">
              <div class="float-left mini-stat-img mr-4">
                <img src="@/assets/images/services-icon/05.png" alt />
              </div>
              <h5 class="font-size-16 text-uppercase mt-0 text-white-50">Users</h5>
              <h4 class="font-weight-medium font-size-24">
                {{ total_users }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card mini-stat bg-primary text-white">
          <div class="card-body">
            <div class="mb-4">
              <div class="float-left mini-stat-img mr-4">
                <img src="@/assets/images/services-icon/02.png" alt />
              </div>
              <h5 class="font-size-16 text-uppercase mt-0 text-white-50">Treatments</h5>
              <h4 class="font-weight-medium font-size-24">
                {{ total_treatments }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card mini-stat bg-primary text-white">
          <div class="card-body">
            <div class="mb-4">
              <div class="float-left mini-stat-img mr-4">
                <img src="@/assets/images/services-icon/01.png" alt />
              </div>
              <h5 class="font-size-16 text-uppercase mt-0 text-white-50">Appointments</h5>
              <h4 class="font-weight-medium font-size-24">
                {{ total_appointments }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card mini-stat bg-primary text-white">
          <div class="card-body">
            <div class="mb-4">
              <div class="float-left mini-stat-img mr-4">
                <img src="@/assets/images/services-icon/04.png" alt />
              </div>
              <h5 class="font-size-16 text-uppercase mt-0 text-white-50">Packages</h5>
              <h4 class="font-weight-medium font-size-24">
                {{ total_packages }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <list-appointment></list-appointment>
    <!-- end row -->
  </Layout>
</template>