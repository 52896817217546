<template>
  <div id="list-appointment">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title mb-4">Today Booked Appointments</h1>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                     <span class="mr-2"> Show </span>
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select> <span class="ml-2">entries</span>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search user..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="table"
                :items="fetchData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :empty-text="'There are no records to show'"
                :empty-filtered-text="'There are no records matching your request'"
              >
                <!-- A virtual column -->
                <template v-slot:cell(No)="data">
                  {{ (currentPage - 1) * perPage + (data.index + 1) }}
                </template> 
                <!-- Full Name -->
                <template v-slot:cell(user)="data">
                  <b>{{ data.item.user }}</b>
                </template>
                <!-- paymentStatus -->
                <template v-slot:cell(payment_status)="data">
                  <template v-if="data.item.checkout">
                    <template v-if="data.item.payment_status == 'paid'">  
                      <b-button variant="success" size="sm" disabled>{{data.item.payment_status}}</b-button>
                    </template>
                    <template v-else-if="data.item.payment_status == 'unpaid'">  
                      <b-button variant="info" size="sm" disabled>{{data.item.payment_status}}</b-button>
                    </template>
                    <template v-else>  
                      <b-button variant="warning" size="sm" disabled>{{data.item.payment_status}}</b-button>
                    </template>
                  </template>
                  <template v-else>
                    <div @click="modalPayment(data.item.appointmentId, data.item.payment_status, data.item.payment_method)">
                      <template v-if="data.item.payment_status == 'paid' && $store.getters.checkPermission('CHANGE_PAYMENT_STATUS_APPOINTMENT')">  
                        <b-button variant="success" size="sm"  v-b-tooltip.hover title="Change Payment Status" >{{data.item.payment_status}}</b-button>
                      </template>
                      <template  v-else-if="data.item.payment_status == 'unpaid'">  
                        <b-button variant="info" size="sm"   v-b-tooltip.hover title="Change Payment Status" >{{data.item.payment_status}}</b-button>
                      </template>
                    </div>
                    <template v-if="data.item.payment_status == 'refunded' && $store.getters.checkPermission('CHANGE_PAYMENT_STATUS_APPOINTMENT')">  
                      <b-button variant="warning" size="sm" disabled>{{data.item.payment_status}}</b-button>
                    </template>
                  </template>
                </template>
                <!-- booking status -->
                <template v-slot:cell(status)="data">
                  <template v-if="data.item.checkout">
                    <template v-if="data.item.status == 'scheduled'">  
                      <b-button variant="warning" disabled size="sm">{{data.item.status}}</b-button>
                    </template>
                    <template v-else-if="data.item.status == 'confirmed'">  
                      <b-button variant="success" disabled size="sm">{{data.item.status}}</b-button>
                    </template>
                      <template v-else-if="data.item.status == 'canceled'">  
                      <b-button variant="danger" disabled size="sm">{{data.item.status}}</b-button>
                    </template>
                    <template v-else-if="data.item.status == 'completed'">  
                      <b-button variant="primary" disabled size="sm">{{data.item.status}}</b-button>
                    </template>
                    <template v-else>  
                      <b-button variant="info" disabled size="sm">{{data.item.status}}</b-button>
                    </template>
                  </template>
                  <template v-else>
                    <div @click="modalStatus(data.item.appointmentId, data.item.status)">
                      <template v-if="data.item.status == 'scheduled' && $store.getters.checkPermission('CHANGE_STATUS_APPOINTMENT')">  
                        <b-button variant="warning"  v-b-tooltip.hover title="Change Status" size="sm">{{data.item.status}}</b-button>
                      </template>
                      <template v-else-if="data.item.status == 'confirmed' && $store.getters.checkPermission('CHANGE_STATUS_APPOINTMENT')">  
                        <b-button variant="success" v-b-tooltip.hover title="Change Status"  size="sm">{{data.item.status}}</b-button>
                      </template>
                        <template v-else-if="data.item.status == 'canceled' && $store.getters.checkPermission('CHANGE_STATUS_APPOINTMENT')">  
                        <b-button variant="danger"  v-b-tooltip.hover title="Change Status" size="sm">{{data.item.status}}</b-button>
                      </template>
                      <template v-else-if="data.item.status == 'completed' && $store.getters.checkPermission('CHANGE_STATUS_APPOINTMENT')">  
                        <b-button variant="primary"  v-b-tooltip.hover title="Change Status" size="sm">{{data.item.status}}</b-button>
                      </template>
                      <template v-else-if="$store.getters.checkPermission('CHANGE_STATUS_APPOINTMENT')">  
                        <b-button variant="info" v-b-tooltip.hover title="Change Status" size="sm">{{data.item.status}}</b-button>
                      </template>
                    </div>
                  </template>
                </template>
                <!-- Aciton -->
                <template v-slot:cell(action)="data">                  
                  <b-button variant="outline-warning"  v-b-tooltip.hover title="View Detail" size="sm" @click.prevent="showAppointmentId = data.item.appointmentId">Detail</b-button> &nbsp;
                  <b-button v-if="$store.getters.checkPermission('CHECKOUT_APPOINTMENT') && !data.item.checkout" variant="outline-danger"  v-b-tooltip.hover title="Checkout Appointment" size="sm" :to="`/appointment/edit/${data.item.appointmentId}`">Checkout</b-button> &nbsp;
                  <b-button v-if="$store.getters.checkPermission('REFUND_APPOINTMENT') && data.item.checkout && data.item.status == 'completed' && data.item.payment_status != 'refunded'" @click="modalRefund(data.item.appointmentId)" variant="outline-success"  v-b-tooltip.hover title="Refund Appointment" size="sm">Refund</b-button> &nbsp;
                  <b-button v-if="$store.getters.checkPermission('PRINT_RECEIPT_APPOINTMENT') && data.item.checkout" :href="data.item.print" target="_blank" variant="outline-primary"  v-b-tooltip.hover title="Print Receipt Appointment" size="sm">Print</b-button> 
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-delete"
      centered
      title="Warning!"
      title-class="font-18"
      @cancel="deleteId = null"
      @ok="deleteData"
    >
      <p>are you sure you want to delete this data ?</p>
    </b-modal>
    
    <b-modal
      id="modal-payment"
      centered
      title="Change Payment Status"
      title-class="font-18"
      @cancel="cancelPayment"
      @ok="confirmChangePayment"
      >
      <label>Change this appointment payment status to</label> 
      <multiselect v-model="paymentStatus" :options="optionsPayment" :allowEmpty="false"></multiselect> 
    </b-modal>

    <b-modal
      id="modal-status"
      centered
      title="Change Status"
      title-class="font-18"
      @cancel="cancelStatus"
      @ok="confirmChangeStatus"
      >
        <label>Change this appointment status to</label> 
        <multiselect v-model="statusVal" :options="optionsStatus" :allowEmpty="false"></multiselect> 
    </b-modal>

    <b-modal
      id="modal-refund"
      centered
      title="Set Appointment to Refunded"
      title-class="font-18"
      @cancel="cancelRefund"
      @ok="confirmRefund"
      >
      <label>Why this appointment set to be refunded ?</label> 
      <textarea
        v-model="refundReason"
        class="form-control"
        placeholder="input your reason here"
        name="reason"
        :class="{ 'is-invalid': isRefundReasonNotFilled}"
      ></textarea>
      <div v-if="isRefundReasonNotFilled" class="invalid-feedback">
        <span>This value is required.</span>
      </div>
    </b-modal>

    <detail-appointment :id="showAppointmentId" @close="showAppointmentId = null"></detail-appointment>
      
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import DetailAppointment from '../appointment/detail-appointment';
import * as moment from 'moment';
/**
 * Advanced table component
 */
export default {
  components: { Multiselect, DetailAppointment },
  data() {
    return {
      showAppointmentId: null,
      appointmentId: null,
      paymentId: null,
      totalRows: 0,
      paymentStatus: null,
      optionsPayment: [
        "paid",
        "unpaid", 
      ],
      paymentMethod: null,
      optionsPaymentMethod: ['debit', 'credit card', 'shopee pay'],
      isPaymentMethodNotSelected: false,
      statusId: null,
      statusVal: null,
      optionsStatus: [
        "scheduled",
        "confirmed",
        "in progress",
        "canceled",
        "completed",
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      sortBy: "createdAt",
      sortDesc: false,
      fields: [
        'No',
        { key: "user", label: "User", sortable: true },
        { key: "location", label: "Clinic", sortable: true }, 
        { key: "date", label: "Date", sortable: true }, 
        { key: "time", label: "Time", sortable: true }, 
        { key: "payment_status", label: "Payment Status", sortable: true }, 
        { key: "status", label: "Status", sortable: true }, 
        'Action'
      ],
      deleteId: null,
      refundReason: null,
      isRefundReasonNotFilled: false
    };
  },
  methods: {
    async fetchData(ctx) { 
      const promise = this.$axios.get(`appointment`, {
        params: {
          createdAt: moment().format('YYYY-MM-DD'),
          locationId: this.$store.getters.getStaffLocationId,
          page: ctx.currentPage,
          limit: ctx.perPage,
          user: ctx.filter,
          sortBy: ctx.sortBy,
          sortType: (ctx.sortDesc)? 1 : -1
        }
      })

      // Must return a promise that resolves to an array of items
      return promise.then(response => {
        // Pluck the array of items off our axios response
        console.log(response)
       
        const items = response.data.result.appointments
        this.totalRows = response.data.result.total
        // Must return an array of items or an empty array if an error occurred
        return items || []
      })
    },
    confirmDelete(roomId) {
      this.deleteId = roomId
      this.$bvModal.show('modal-delete')
    },
    deleteData() {
      this.$axios.delete(`appointment/${this.deleteId}`)
        .then( response => {
          console.log(response.data)
          Swal.fire("Success", "Appointment Deleted Successfully", "success");
          this.$refs.table.refresh();
        }).catch( error => {
          console.log(error.response)
        })
    },
    // payment popup
    modalPayment(appointmentId, payment_status, payment_method){
      this.appointmentId = appointmentId
      this.paymentStatus = payment_status
      this.paymentMethod = payment_method
      this.$bvModal.show('modal-payment')
    },
    confirmChangePayment(event){
      event.preventDefault()
      if (this.paymentStatus == 'paid' && this.paymentMethod === null) {
        this.isPaymentMethodNotSelected = true;
      } else {
        this.isPaymentMethodNotSelected = false;
        let param = new FormData();
        param.append('payment_status', this.paymentStatus)
        param.append('payment_method', (this.paymentStatus == 'paid')? this.paymentMethod : '')
        this.$axios.put('/appointment/'+this.appointmentId+'/payment-status',param)
          .then(response => {
            console.log('res',response)
            this.cancelPayment()  
            Swal.fire("Success", "Payment Status Changed Successfully", "success");
            this.$refs.table.refresh(); 
          })
      }
    }, 
    cancelPayment(){
      this.appointmentId = null
      this.paymentStatus = null
      this.paymentMethod = null      
      this.$bvModal.hide('modal-payment') 
    },
    // status change
    modalStatus(appointmentId, status){
      this.appointmentId = appointmentId
      this.statusVal = status
      this.$bvModal.show('modal-status')
    },
    confirmChangeStatus(){
      let param = new FormData();
      param.append('status', this.statusVal)
      this.$axios.put('/appointment/'+this.appointmentId+'/status', param)
        .then(response => {
          console.log('res',response)
          this.cancelStatus()   
          Swal.fire("Success", "Status Changed Successfully", "success");
          this.$refs.table.refresh(); 
        })
    }, 
    cancelStatus(){
      this.appointmentId = null
      this.statusVal = null      
      this.$bvModal.hide('modal-status') 
    },
    // refund popup
    modalRefund(appointmentId) {
      this.appointmentId = appointmentId
      this.$bvModal.show('modal-refund') 
    },
    confirmRefund(event) {
      event.preventDefault()
      if (this.refundReason === null || this.refundReason == "") {
        this.isRefundReasonNotFilled = true;
      } else {
        this.isRefundReasonNotFilled = false;
        let param = new FormData();
        param.append('reason', this.refundReason)
        this.$axios.put('/appointment/'+this.appointmentId+'/refund',param)
          .then(response => {
            console.log('res',response)
            this.cancelRefund()  
            Swal.fire("Success", "Appointment Refunded Success", "success");
            this.$refs.table.refresh(); 
          })
      }
    },
    cancelRefund() {
      this.appointmentId = null
      this.refundReason = null  
      this.$bvModal.hide('modal-refund') 
    },
  }
};
</script>